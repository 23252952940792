
/* --------------------------------------------------- 
    You can add global styles to this file, and also 
    import other style files
----------------------------------------------------- */

@import "../node_modules/snl-complib/themes/theme-colors.scss";
@import "../node_modules/snl-complib/themes/typography.scss";
@import "../node_modules/snl-complib/themes/spacing.scss";
@import "../node_modules/snl-complib/themes/common.scss";
@import "../node_modules/snl-complib/themes/snl-complib.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");

/* ---------------------------------------------------
    STOLT CCD SKELETON STYLE
----------------------------------------------------- */
.Stolt-Skeleton{ 
    min-width:50px;
    min-height:10px;  
    display:flex;
    cursor: wait !important;
    flex-direction:column;
    background: #d9d9d9;
    .ng-select-container{
      border: none;
    }
    background: rgba(130, 130, 130, 0.2);
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
    background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
    background-size: 2000px 200px;
    animation: wave-squares 2s infinite ease-out;
  }
  @keyframes wave-squares {
    0% {
        background-position: -1000px 0;
    }
     100% {
        background-position: 1000px 0;
    }
  }

  .filter{
    .Stolt-Skeleton{
        min-width: 130px;
        min-height: var(--spacing-48); 
        border-radius: var(--spacing-8);
    }
  }

  .BtnSkeleton{
    &.Stolt-Skeleton{
      display: inline-flex;
      border-radius: var(--spacing-4);
      button{
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  body{
    overflow: auto !important;
  }

/* ---------------------------------------------------
  STOLT CCD COMMON TABLE CUSTOMIZATION STYLE
----------------------------------------------------- */
  snl-table{
    .table__wrapper{
     snl-row{
      &.bg-theader{
        background-color: var(--neutral-10);
        snl-th{
          vertical-align: top;
          &.HeightManager{
            .th__wrapper{
              height: auto;
              padding-top: var(--spacing-12);
              padding-bottom: var(--spacing-12);
              .th__text{
               align-items: flex-start !important;
              }
            }
          }
        }
      }
       &.ColWidthMain{
        snl-th, snl-td{
          .overflowing{
            &:hover{
              margin-left: -5px !important;
            }
          }
          &:nth-child(1){
            min-width: 96px;
            max-width: 96px;
            .td__wrapper {
              .data-text{
                max-width: 96px !important;
              }
            }
          }
          &:nth-child(2){
            min-width: 160px; 
            max-width: 160px;
            .td__wrapper {
              .data-text{
                max-width: 160px !important;
              }
            }
          }
          &:nth-child(3){
            min-width: 86px;
            max-width: 86px;
            .td__wrapper {
              .data-text{
                max-width: 86px !important;
              }
            }
          }
          &:nth-child(4){
            min-width: 80px;
            max-width: 80px;
            .td__wrapper {
              .data-text{
                max-width: 80px !important;
              }
            }
          }
          &:nth-child(5){
            min-width: 140px;
            max-width: 140px;
            .td__wrapper {
              .data-text{
                max-width: 140px !important;
              }
            }
          }
          &:nth-child(6){
            min-width: 126px;
            max-width: 126px;
            .td__wrapper {
              .data-text{
                max-width: 126px !important;
              }
            }
          }
          &:nth-child(7){
            min-width: 126px;
            max-width: 126px;
            .td__wrapper {
              .data-text{
                max-width: 126px !important;
              }
            }
          }
          &.iconCol120{
            min-width: 120px;
            max-width: 120px;
            .td__wrapper {
              .data-text{
                max-width: 120px !important;
              }
            }
          } 
          &:nth-child(14){
            min-width: 129px;
            max-width: 129px;
            .td__wrapper {
              .data-text{
                max-width: 129px !important;
              }
            }
          }
          &:nth-child(15){
            min-width: 117.5px;
            max-width: 117.5px;
            .td__wrapper {
              .data-text{
                max-width: 117.5px !important;
              }
            }
          }
          &:nth-child(16){
            min-width: 117.5px;
            max-width: 117.5px;
            .td__wrapper {
              .data-text{
                max-width: 117.5px !important;
              }
            }
          }
          &.CancellationNotesCol{
            min-width: 240px !important;
            max-width: 280px !important;
            .td__wrapper {
              .data-text{
                max-width: 280px !important;
              }
            }
          }
          &.DatePublishedCol{
            min-width: 145px !important;
            max-width: 145px !important;
            .td__wrapper {
              .data-text{
                max-width: 145px !important;
              }
            }
          }
          &.ReactivateCol{
            min-width: 150px !important;
            max-width: 150px !important; 
            .td__wrapper {
              .data-text{
                max-width: 150px !important;
              }
            }
          } 
        }
       }
       &:last-child{
        snl-td{
          border-bottom: none;
        }
       }
     }
    }
    &.TableSkeleton{
      .table__wrapper{
        snl-row{
          snl-td{
            .td__wrapper{
              .data-text{
                min-height: 64px;
                .text-content{
                  width: 100%;
                  .Stolt-Skeleton{
                    width: 100%;
                    border-radius: 20px;
                  }
                } 
              }
            }
          }
        }
      }
    }
    .scrollTable{
      &::-webkit-scrollbar-track {
        background-color: var(--neutral-11); 
      }
      &::-webkit-scrollbar-thumb{
        background-color: var(--neutral-9);
      }
      &::-webkit-scrollbar{
        height: 10px;
      }
    }
    &.TabelAddUser{
      .table__wrapper{
        snl-row{
          snl-th, snl-td{ 
            .text-content{
              color: var(--font-color-primary);
            }
            .trash_icn{
              cursor: pointer;
            }
            vertical-align:top;
            &.InitialName{
              .text-content{
                color: var(--primary-default);
                cursor: pointer;
                font-weight: 700;
              }
            }
            &:nth-child(1){
              min-width: 227px;
              max-width: 227px;
              &.NoDataFoundCenter{
                .td__wrapper {
                  .data-text{
                    max-width: inherit !important;
                    justify-content: center;
                  }
                }
              }
              .td__wrapper {
                .data-text{
                  max-width: 227px !important;
                }
              }
            } 
            &:nth-child(8){
              min-width: 120px;
              max-width: 120px;
              .td__wrapper {
                .data-text{
                  max-width: 120px !important;
                }
              }
            }       
        }
        }
      }
    }
 }

 


/* ---------------------------------------------------
    STOLT CCD TOOLTIP CUSTOMIZATION STYLE
----------------------------------------------------- */
 .TpCustomization{
  max-width: 400px;
  &.fixed240{
    min-width: 240px;
    max-width: 240px;
  }
  .body-b1{ 
    color: var(--font-color-primary);
    white-space:pre-line;
  }
 }

 .border-b-dash{
  border-bottom: 1.5px dashed var(--font-color-primary);
 }





/* ---------------------------------------------------
    STOLT CCD MODEL CUSTOMIZATION STYLE
----------------------------------------------------- */
 .CommnModalChanges{
    .wrapper{
      z-index: 99999 !important;
      .modal{
        .modal__header{
          .modal__header-icon{
            snl-icon{ 
              outline: none;
              .fa-close{ 
                -webkit-text-stroke: var(--spacing-1) var(--neutral-12);
              }
            }
          }
        }
        &.modal__wrapper-small{
          max-width: 520px !important;
          min-width: 520px; 
          .modal__content-small{
            overflow-y: inherit !important;
          }
        }
      }
    }
    &.CancelCCD{
      .wrapper{
        .modal{
          .modal__header{
            padding-bottom: 0;
          }
          .modal__content-small{
            padding-top: var(--spacing-24) !important;
          }
        }
      }
    }
    &.CoomonModel400{
      .modal{
        &.modal__wrapper-small{
          max-width: 400px !important;
          min-width: 400px; 
          .modal__content-small{
            overflow-y: inherit !important;
            .body-b1{
              color: var(--font-color-primary);
            }
          }
        }
      }
     }

    &.DeleteModel{
      .modal{
        &.modal__wrapper-small{
          max-width: 343px !important;
          min-width: 343px; 
          p{
            margin: 0;
            color: var(--font-color-primary);
          }
        }
        .DeleteClr{
          .snl-button{
            background-color: var(--semantic-9);
          }
        }
      }
    }
 }


 .AddNewUserMdl{
  &.EditUserMdl{
    .wrapper{
      .modal{
        max-height: 623px;
      }
    }
  }
  .modal__header{
    height: 64px;
    max-height: 64px;
    padding-bottom: 0 !important;
  }
  .wrapper{
    .modal{
      width: 440px;
      max-width: 440px;
      max-height: 712px;
      .AddNewWrap{
        display: flex;
        flex-direction: column;
        gap: 32px;
        margin-top: 24px;
      }
      ul{
        display: flex;
        flex-direction: column;
        gap: 24px;
        li{
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          gap: 16px;
          min-height: 32px;
          snl-input{
            width: 100%;
          }
          snl-dropdown{
            width: 100%;
            .container__dropdown{
              display: flex;
              align-items: center;
              width: 100%;
              .container__dropdown--labelT, .container__dropdown--label{
                width: 100%;
                display: inline-flex;
                &.top-left{
                  width: auto;
                }
              }
            }
          }
        }
       }
    }
  }
  .modal__footer{
    height: 80px;
    max-height: 80px;
  }
 }


/* ---------------------------------------------------
  STOLT CCD TOAST CUSTOMIZATION STYLE
----------------------------------------------------- */
snl-toast{
  position: fixed !important;
  bottom: 40px !important;
  right: 40px;
}


/* ---------------------------------------------------
  STOLT CCD COMMON COMPONENTS CUSTOMIZATION STYLE
----------------------------------------------------- */
ul{
  list-style: none;
  padding: 0;
} 
.main {
  display: flex;
  flex-direction: column;
  .DataBodyContainer {
      padding-left: 216px;
      width: 100%;
      min-height: 100vh;
      background: var(--neutral-11);
      .page-content{
        padding: 0 var(--spacing-24) var(--spacing-24);
      }
  }
}
snl-input{
  display: block;
  height: var(--spacing-48);
}
.snl-button{
  &:disabled {
    cursor: not-allowed !important;
  }
}

.gap16{
  gap: var(--spacing-16);
}

.FixedFooterBar {
  position: fixed; 
  width: calc(100% - 216px);
  right: 0;
  bottom: 0;
  z-index: 999; 
  .MessageBtm{
    gap: 6px;
  }
}


.SwitchCstm{
  display: flex;
  gap: var(--spacing-8);
  color: var(--font-color-secondary);
}

snl-switch{
  display: flex;
  width: 64px;
  height: 32px;
  &.disabled{
    cursor: not-allowed;
    .switch__slider{
      cursor: not-allowed;
    }
  }
  &.LargeSwitch{
    .switch__wrapper{
      min-width: 100%;
      height: 100%;
      .switch__slider{  
        min-width: 100%;
        height: 100%;
        &.active{
          .switch__toggle{
            transform: translate(0) !important;
          }
        }
        .switch__toggle{
          height: 26px;
          width: 26px;
          transform: translate(calc(-100% + var(--spacing-24))) !important;
          margin-top: -1px;
        }
      }
    }
    &.disabled{
      .switch__slider{
        cursor: not-allowed;
      }
    }
  }
  &.disabled-toggle{
    .switch__slider{
      cursor: not-allowed;
    }
  }
}

.container__dropdown--open{
  width: 100%;
  max-width: 350px;
  snl-menu-list-item{
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
  .container__menu-item{
    display: flex;
    .container__menu-item--value{
      word-wrap: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 30px;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &:hover{
        display: flex;
        overflow: inherit;
        max-height: inherit;
        width: 100%;
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        word-wrap: break-word;
        min-width: auto;
        word-break: break-all;
      }
    }
  }
}

.alertMain{
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: var(--spacing-24);
  min-width: 1100px;
  width: fit-content;
  position: sticky;
  left: 240px; 
  .alertIn{
    background:var(--semantic-6);
    min-width: 1000px;
    padding: var(--spacing-8) var(--spacing-16);
    min-height: 42px;
    display: flex;
    align-items: center;
    gap: var(--spacing-8);
    color: var(--font-color-primary);
    border-radius: var(--spacing-4);
    justify-content: space-between;
    .alertInner{
      display: flex;
      gap: var(--spacing-8);
      svg-icon{
        display: inline-flex;
     }
    }

  }
  a{
    color: var(--primary-default);
    font-weight: 700;
  }
}


.DragDropFile{
  &.disable-upload{
    cursor: not-allowed;
    opacity: 0.5;
    .file__choose{
      pointer-events: none;
    }
  }
  .caption{
    color: var(--font-color-secondary);
  }
  .file__choose{ 
    border: var(--spacing-1) dashed var(--neutral-8);
    color: var(--font-color-secondary) !important;
    border-radius: var(--spacing-8);
    min-height: 80px;
    display: flex;
    align-items: center;
    gap: var(--spacing-24);
    justify-content: space-between;
    padding: var(--spacing-16);
    margin: var(--spacing-8) 0 var(--spacing-4) 0;
    cursor: pointer; 
    &:hover{
      border: 1px dashed var(--Primary-P3---Selected, #004869);
      .uploadBtn { 
        background-color: var(--primary-accent);
        color: var(--primary-hover);
        svg{
          path{
            fill: var(--primary-hover);
          }
        }
      }
    }
    .uploadBtn {  
      min-width: 162px;
      border: 1px solid #008ECF;
      color: #008ECF;
      border-radius: var(--spacing-4);
      min-height: var(--spacing-48);
      display: flex;
      gap: var(--spacing-8);
      align-items: center;
      justify-content: center;
      padding: 8px 32px;
      &:hover{
        background-color: var(--primary-accent);
        color: var(--primary-hover);
        svg{
          path{
            fill: var(--primary-hover);
          }
        }
      }
   }
   
   #upload-file {
      opacity: 0;
      position: absolute;
      z-index: -1;
      width: 0;
   } 
  } 
}

.clr__secondary{
  color: #64748B;
}

.cdk-overlay-container { 
  z-index: 99999 !important;
}


.floating-input-container {
  position: relative;
  width: 100%; 
  svg-icon{
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .floating-input {
    width: 100%;
    padding: 14px 41px 14px var(--spacing-12);
    font-size: var(--spacing-16);
    border: var(--border-width-1) solid var(--neutral-9);
    border-radius: var(--spacing-4);
    box-sizing: border-box;
    outline: none; 
    &:focus{
      border-color: var(--primary-default);
    }
    &::placeholder {
      color: transparent;
    }
    &:focus + .floating-label,
    &:not(:placeholder-shown) + .floating-label {
      top: 0;
      left:10px;
      font-size: var(--spacing-12);
      line-height: var(--spacing-16);
      color: var(--primary-default); 
      background: var(--neutral-12);
      padding: 0 var(--spacing-2); 
    }
  }
  .floating-label {
    position: absolute;
    top: 50%;
    left: var(--spacing-12);
    transform: translateY(-50%);
    font-size: var(--spacing-16);
    line-height: var(--spacing-24); 
    pointer-events: none;
    transition: all 0.3s ease;
  }

  .DropDownCstm{
    position: absolute;
    background: var(--neutral-12);
    z-index: 99; 
    box-shadow: 0px 10px 15px 0px #0F1A2A1A; 
    width: 100%;
    margin-top: var(--spacing-8);
    border: var(--spacing-1) solid #f7ecec;
    border-radius: var(--spacing-4);
    ul{
      gap: 0 !important;
      li{
        padding: var(--spacing-12) var(--spacing-16);
        color: var(--font-color-primary);
        display: inline !important;
        cursor: pointer;
        &:hover{
          background-color: var(--neutral-11);
        }
      }
    }
  }
}
.NoDataFoundCenter{
  position: sticky;
  left: 50%;
  transform: translate(-50%, 0);
  .data-text{
    .overflowing{
      display: flex;
    }
  }
}
.container__dropdown-border--selected{
  border: var(--border-width-1) solid var(--neutral-8) !important;
}
.container__dropdown-border:hover {
  border: var(--border-width-1) solid var(--primary-hover) !important;
}
.star-required{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-16);
  .note{
    font-family: var(--font-family-roboto-regular);
    font-size: var(--font-size-16);
    color: var(--font-color-primary);
    font-weight: 400;
  }
}


/* ---------------------------------------------------
    PAGE TABS WITH ICONS STYLE
----------------------------------------------------- */
.TabsHolder{
  padding-top:var(--spacing-16);
  border-bottom: var(--spacing-1) solid var(--neutral-9);
  margin-bottom: var(--spacing-8);
  snl-page-tab{
    .page-tab__content{
      display: flex;
      gap: var(--spacing-8);
    }
  }
  ul{
    padding: 0 var(--spacing-16);
    display: flex;
    gap: var(--spacing-24);
    margin-bottom: var(--spacing-24);
    li{
      &.Stolt-Skeleton{
        width: 100%;
        max-width: 100px;
        min-height: 24px;
        border-radius: 20px;
      }
    }
  }
}


/* ---------------------------------------------------
  STOLT CCD SIDEBAR CUSTOMIZATION STYLE
----------------------------------------------------- */
.sidebar {
  position: fixed;
  z-index: 9999;
  .scroll{
    overflow-x: hidden;
  }
}
.leftbar-collapsed{
  .DataBodyContainer{
    padding-left: var(--spacing-72) !important;
  }
  .FixedFooterBar{
    width: calc(100% - 77px);
  }
}


/* ---------------------------------------------------
  STOLT CCD TRUNCATE CUSTOMIZATION STYLE
----------------------------------------------------- */
.truncate-text{
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.rotating-icon {
  animation: rotate 2s linear infinite;
}
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.ck-balloon-panel{
  display: none !important;
}
.ck-editor__editable_inline {
  max-height: 150px;
  &::-webkit-scrollbar {
    width: var(--spacing-8);
    height: var(--spacing-8);
  }
  &::-webkit-scrollbar-track {
    background-color: var(--scroll-background);
    border-width: 0px 0.5px;
    border-style: solid;
    border-color: var(--scroll-border);
  }
  &::-webkit-scrollbar-thumb {
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    background-clip: padding-box;
    border-radius: var(--border-radius-8);
    background-color: var(--scroll-thumb-background);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: var(--scroll-thumb-hover);
  }
}

.container__dropdown--open-actions{
  padding-top: 0 !important;
}