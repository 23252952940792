/* --------------------------------------------------- 
    You can add global styles to this file, and also 
    import other style files
----------------------------------------------------- */
@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
:root {
  /**
  *Neutral Colors
  */
  --neutral-1: #0F1A2A;
  --neutral-2: #1E2A3B;
  --neutral-3: #27364B;
  --neutral-4: #475569;
  --neutral-5: #64748B;
  --neutral-6: #788496;
  --neutral-7: #94A3B8;
  --neutral-8: #CBD4E1;
  --neutral-9: #DBE3ED;
  --neutral-10: #F1F4F9;
  --neutral-11: #F6F8FC;
  --neutral-12: #FFFFFF;
  /**
  *Primary
  */
  --primary-default: #008ECF;
  --primary-hover: #006B9C;
  --primary-selected: #004869;
  --primary-disabled: #CBD4E1;
  --primary-accent: #E5F4FA;
  /**
  *Font Colors
  */
  --font-color-primary: #27364B;
  --font-color-secondary: #64748B;
  --font-color-neutral: #8392A8;
  --font-color-disabled: #B8BFCA;
  --font-color-button: #FFFFFF;
  /**
  *Pastel Colors
  */
  --pastel-1: #FFADAD;
  --pastel-2: #FEC89A;
  --pastel-3: #7FC8F8;
  --pastel-4: #E0D2FF;
  --pastel-5: #FFE8B7;
  --pastel-6: #C7F5E3;
  --pastel-7: #DAD1CA;
  --pastel-8: #FCF3D3;
  --pastel-9: #ECCBD9;
  --pastel-10: #AFEFF5;
  /**
  *Semantic Colors
  */
  --semantic-1: #0C9E0B;
  --semantic-2: #E8FCF1;
  --semantic-3: #A5E1BF;
  --semantic-4: #00401C;
  --semantic-5: #FFA300;
  --semantic-6: #FFF5D5;
  --semantic-7: #FFDE81;
  --semantic-8: #724B00;
  --semantic-9: #CF0027;
  --semantic-10: #FFEBEB;
  --semantic-11: #FC9595;
  --semantic-12: #8C0000;
  /**
  * Scroller Colors
  */
  --scroll-background: #fafafa;
  --scroll-border: #e8e8e8;
  --scroll-thumb-background: #d9d9d9;
  --scroll-thumb-hover: #7d7d7d;
  /**
  * backdrop background
  */
  --backdrop-background: rgba(0,0,0,.32);
}

/**
  * Common font family
  */
@font-face {
  font-family: pt-serif-bold;
  src: url("./fonts/PTSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-regular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
:root {
  /**
  * Font weight
  */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  /**
  * Common font family
  */
  --font-family-pt-serif-bold: pt-serif-bold;
  --font-family-roboto-bold: roboto-bold;
  --font-family-roboto-regular: roboto-regular;
  /**
  * Letter spacing
  */
  --letter-spacing-2: 0.2px;
  /**
  * Border width
  */
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;
  /**
  * Border radius
  */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --border-radius-999: 999px;
  /**
  * Font size
  */
  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-4: 4px;
  --font-size-8: 8px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --font-size-80: 80px;
  /**
  * Font style
  */
  --font-style-normal: normal;
  /**
  * Line Height
  */
  --line-height-16: 16px;
  --line-height-24: 24px;
  --line-height-32: 32px;
  --line-height-40: 40px;
  /**
    *Elevations
    */
  --elevation-e1: 0px 1px 2px rgba(15, 26, 42, 0.25);
  --elevation-e2: 0px 0px 20px rgba(15, 26, 42, 0.15);
  --elevation-e3: 0px 10px 15px rgba(15, 26, 42, 0.1);
  --elevation-e4: 0px -2px 15px rgba(15, 26, 42, 0.1);
  --elevation-e5: 0px 3px 12px rgba(15, 26, 42, 0.25);
}

:root {
  /**
  *Spacing
  */
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-38: 38px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
}

.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resize-date-picker-overlay {
  width: auto !important;
}

snl-menu-list-item :focus-visible {
  outline: var(--neutral-10) solid 0px;
}

.container__menu-item--value b {
  color: var(--dropdown-label-value-color);
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS for both top and bottom scrollbars */
.tableWrapper::-webkit-scrollbar,
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
  height: var(--spacing-8);
}

.tableWrapper::-webkit-scrollbar-track,
.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.tableWrapper::-webkit-scrollbar-thumb,
.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}

.tableWrapper::-webkit-scrollbar-thumb:hover,
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

/* Top scrollbar wrapper */
.tableWrapper {
  width: 100%;
  height: 8px;
  overflow-x: auto; /* Horizontal scroll enabled */
  overflow-y: hidden;
}

.wrapperTop {
  width: 100%;
  height: 0px;
  overflow-x: hidden; /* Horizontal scroll enabled */
  overflow-y: hidden;
}

/* Hidden div to simulate top scrollbar width */
.div1 {
  width: 2000px; /* Ensure this is wider than the table */
  height: 20px;
}

/* Bottom scroll wrapper */
.scroll {
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.scrollTop {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

/* ---------------------------------------------------
    STOLT CCD SKELETON STYLE
----------------------------------------------------- */
.Stolt-Skeleton {
  min-width: 50px;
  min-height: 10px;
  display: flex;
  cursor: wait !important;
  flex-direction: column;
  background: #d9d9d9;
  background: rgba(130, 130, 130, 0.2);
  background: -webkit-gradient(linear, left top, right top, color-stop(8%, rgba(130, 130, 130, 0.2)), color-stop(18%, rgba(130, 130, 130, 0.3)), color-stop(33%, rgba(130, 130, 130, 0.2)));
  background: linear-gradient(to right, rgba(130, 130, 130, 0.2) 8%, rgba(130, 130, 130, 0.3) 18%, rgba(130, 130, 130, 0.2) 33%);
  background-size: 2000px 200px;
  animation: wave-squares 2s infinite ease-out;
}
.Stolt-Skeleton .ng-select-container {
  border: none;
}

@keyframes wave-squares {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.filter .Stolt-Skeleton {
  min-width: 130px;
  min-height: var(--spacing-48);
  border-radius: var(--spacing-8);
}

.BtnSkeleton.Stolt-Skeleton {
  display: inline-flex;
  border-radius: var(--spacing-4);
}
.BtnSkeleton.Stolt-Skeleton button {
  opacity: 0;
  pointer-events: none;
}

body {
  overflow: auto !important;
}

/* ---------------------------------------------------
  STOLT CCD COMMON TABLE CUSTOMIZATION STYLE
----------------------------------------------------- */
snl-table .table__wrapper snl-row.bg-theader {
  background-color: var(--neutral-10);
}
snl-table .table__wrapper snl-row.bg-theader snl-th {
  vertical-align: top;
}
snl-table .table__wrapper snl-row.bg-theader snl-th.HeightManager .th__wrapper {
  height: auto;
  padding-top: var(--spacing-12);
  padding-bottom: var(--spacing-12);
}
snl-table .table__wrapper snl-row.bg-theader snl-th.HeightManager .th__wrapper .th__text {
  align-items: flex-start !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th .overflowing:hover, snl-table .table__wrapper snl-row.ColWidthMain snl-td .overflowing:hover {
  margin-left: -5px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(1), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(1) {
  min-width: 96px;
  max-width: 96px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(1) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(1) .td__wrapper .data-text {
  max-width: 96px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(2), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(2) {
  min-width: 160px;
  max-width: 160px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(2) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(2) .td__wrapper .data-text {
  max-width: 160px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(3), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(3) {
  min-width: 86px;
  max-width: 86px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(3) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(3) .td__wrapper .data-text {
  max-width: 86px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(4), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(4) {
  min-width: 80px;
  max-width: 80px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(4) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(4) .td__wrapper .data-text {
  max-width: 80px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(5), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(5) {
  min-width: 140px;
  max-width: 140px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(5) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(5) .td__wrapper .data-text {
  max-width: 140px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(6), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(6) {
  min-width: 126px;
  max-width: 126px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(6) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(6) .td__wrapper .data-text {
  max-width: 126px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(7), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(7) {
  min-width: 126px;
  max-width: 126px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(7) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(7) .td__wrapper .data-text {
  max-width: 126px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.iconCol120, snl-table .table__wrapper snl-row.ColWidthMain snl-td.iconCol120 {
  min-width: 120px;
  max-width: 120px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.iconCol120 .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td.iconCol120 .td__wrapper .data-text {
  max-width: 120px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(14), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(14) {
  min-width: 129px;
  max-width: 129px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(14) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(14) .td__wrapper .data-text {
  max-width: 129px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(15), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(15) {
  min-width: 117.5px;
  max-width: 117.5px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(15) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(15) .td__wrapper .data-text {
  max-width: 117.5px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(16), snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(16) {
  min-width: 117.5px;
  max-width: 117.5px;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th:nth-child(16) .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td:nth-child(16) .td__wrapper .data-text {
  max-width: 117.5px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.CancellationNotesCol, snl-table .table__wrapper snl-row.ColWidthMain snl-td.CancellationNotesCol {
  min-width: 240px !important;
  max-width: 280px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.CancellationNotesCol .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td.CancellationNotesCol .td__wrapper .data-text {
  max-width: 280px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.DatePublishedCol, snl-table .table__wrapper snl-row.ColWidthMain snl-td.DatePublishedCol {
  min-width: 145px !important;
  max-width: 145px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.DatePublishedCol .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td.DatePublishedCol .td__wrapper .data-text {
  max-width: 145px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.ReactivateCol, snl-table .table__wrapper snl-row.ColWidthMain snl-td.ReactivateCol {
  min-width: 150px !important;
  max-width: 150px !important;
}
snl-table .table__wrapper snl-row.ColWidthMain snl-th.ReactivateCol .td__wrapper .data-text, snl-table .table__wrapper snl-row.ColWidthMain snl-td.ReactivateCol .td__wrapper .data-text {
  max-width: 150px !important;
}
snl-table .table__wrapper snl-row:last-child snl-td {
  border-bottom: none;
}
snl-table.TableSkeleton .table__wrapper snl-row snl-td .td__wrapper .data-text {
  min-height: 64px;
}
snl-table.TableSkeleton .table__wrapper snl-row snl-td .td__wrapper .data-text .text-content {
  width: 100%;
}
snl-table.TableSkeleton .table__wrapper snl-row snl-td .td__wrapper .data-text .text-content .Stolt-Skeleton {
  width: 100%;
  border-radius: 20px;
}
snl-table .scrollTable::-webkit-scrollbar-track {
  background-color: var(--neutral-11);
}
snl-table .scrollTable::-webkit-scrollbar-thumb {
  background-color: var(--neutral-9);
}
snl-table .scrollTable::-webkit-scrollbar {
  height: 10px;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th, snl-table.TabelAddUser .table__wrapper snl-row snl-td {
  vertical-align: top;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th .text-content, snl-table.TabelAddUser .table__wrapper snl-row snl-td .text-content {
  color: var(--font-color-primary);
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th .trash_icn, snl-table.TabelAddUser .table__wrapper snl-row snl-td .trash_icn {
  cursor: pointer;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th.InitialName .text-content, snl-table.TabelAddUser .table__wrapper snl-row snl-td.InitialName .text-content {
  color: var(--primary-default);
  cursor: pointer;
  font-weight: 700;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th:nth-child(1), snl-table.TabelAddUser .table__wrapper snl-row snl-td:nth-child(1) {
  min-width: 227px;
  max-width: 227px;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th:nth-child(1).NoDataFoundCenter .td__wrapper .data-text, snl-table.TabelAddUser .table__wrapper snl-row snl-td:nth-child(1).NoDataFoundCenter .td__wrapper .data-text {
  max-width: inherit !important;
  justify-content: center;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th:nth-child(1) .td__wrapper .data-text, snl-table.TabelAddUser .table__wrapper snl-row snl-td:nth-child(1) .td__wrapper .data-text {
  max-width: 227px !important;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th:nth-child(8), snl-table.TabelAddUser .table__wrapper snl-row snl-td:nth-child(8) {
  min-width: 120px;
  max-width: 120px;
}
snl-table.TabelAddUser .table__wrapper snl-row snl-th:nth-child(8) .td__wrapper .data-text, snl-table.TabelAddUser .table__wrapper snl-row snl-td:nth-child(8) .td__wrapper .data-text {
  max-width: 120px !important;
}

/* ---------------------------------------------------
    STOLT CCD TOOLTIP CUSTOMIZATION STYLE
----------------------------------------------------- */
.TpCustomization {
  max-width: 400px;
}
.TpCustomization.fixed240 {
  min-width: 240px;
  max-width: 240px;
}
.TpCustomization .body-b1 {
  color: var(--font-color-primary);
  white-space: pre-line;
}

.border-b-dash {
  border-bottom: 1.5px dashed var(--font-color-primary);
}

/* ---------------------------------------------------
    STOLT CCD MODEL CUSTOMIZATION STYLE
----------------------------------------------------- */
.CommnModalChanges .wrapper {
  z-index: 99999 !important;
}
.CommnModalChanges .wrapper .modal .modal__header .modal__header-icon snl-icon {
  outline: none;
}
.CommnModalChanges .wrapper .modal .modal__header .modal__header-icon snl-icon .fa-close {
  -webkit-text-stroke: var(--spacing-1) var(--neutral-12);
}
.CommnModalChanges .wrapper .modal.modal__wrapper-small {
  max-width: 520px !important;
  min-width: 520px;
}
.CommnModalChanges .wrapper .modal.modal__wrapper-small .modal__content-small {
  overflow-y: inherit !important;
}
.CommnModalChanges.CancelCCD .wrapper .modal .modal__header {
  padding-bottom: 0;
}
.CommnModalChanges.CancelCCD .wrapper .modal .modal__content-small {
  padding-top: var(--spacing-24) !important;
}
.CommnModalChanges.CoomonModel400 .modal.modal__wrapper-small {
  max-width: 400px !important;
  min-width: 400px;
}
.CommnModalChanges.CoomonModel400 .modal.modal__wrapper-small .modal__content-small {
  overflow-y: inherit !important;
}
.CommnModalChanges.CoomonModel400 .modal.modal__wrapper-small .modal__content-small .body-b1 {
  color: var(--font-color-primary);
}
.CommnModalChanges.DeleteModel .modal.modal__wrapper-small {
  max-width: 343px !important;
  min-width: 343px;
}
.CommnModalChanges.DeleteModel .modal.modal__wrapper-small p {
  margin: 0;
  color: var(--font-color-primary);
}
.CommnModalChanges.DeleteModel .modal .DeleteClr .snl-button {
  background-color: var(--semantic-9);
}

.AddNewUserMdl.EditUserMdl .wrapper .modal {
  max-height: 623px;
}
.AddNewUserMdl .modal__header {
  height: 64px;
  max-height: 64px;
  padding-bottom: 0 !important;
}
.AddNewUserMdl .wrapper .modal {
  width: 440px;
  max-width: 440px;
  max-height: 712px;
}
.AddNewUserMdl .wrapper .modal .AddNewWrap {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
}
.AddNewUserMdl .wrapper .modal ul {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.AddNewUserMdl .wrapper .modal ul li {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 16px;
  min-height: 32px;
}
.AddNewUserMdl .wrapper .modal ul li snl-input {
  width: 100%;
}
.AddNewUserMdl .wrapper .modal ul li snl-dropdown {
  width: 100%;
}
.AddNewUserMdl .wrapper .modal ul li snl-dropdown .container__dropdown {
  display: flex;
  align-items: center;
  width: 100%;
}
.AddNewUserMdl .wrapper .modal ul li snl-dropdown .container__dropdown .container__dropdown--labelT, .AddNewUserMdl .wrapper .modal ul li snl-dropdown .container__dropdown .container__dropdown--label {
  width: 100%;
  display: inline-flex;
}
.AddNewUserMdl .wrapper .modal ul li snl-dropdown .container__dropdown .container__dropdown--labelT.top-left, .AddNewUserMdl .wrapper .modal ul li snl-dropdown .container__dropdown .container__dropdown--label.top-left {
  width: auto;
}
.AddNewUserMdl .modal__footer {
  height: 80px;
  max-height: 80px;
}

/* ---------------------------------------------------
  STOLT CCD TOAST CUSTOMIZATION STYLE
----------------------------------------------------- */
snl-toast {
  position: fixed !important;
  bottom: 40px !important;
  right: 40px;
}

/* ---------------------------------------------------
  STOLT CCD COMMON COMPONENTS CUSTOMIZATION STYLE
----------------------------------------------------- */
ul {
  list-style: none;
  padding: 0;
}

.main {
  display: flex;
  flex-direction: column;
}
.main .DataBodyContainer {
  padding-left: 216px;
  width: 100%;
  min-height: 100vh;
  background: var(--neutral-11);
}
.main .DataBodyContainer .page-content {
  padding: 0 var(--spacing-24) var(--spacing-24);
}

snl-input {
  display: block;
  height: var(--spacing-48);
}

.snl-button:disabled {
  cursor: not-allowed !important;
}

.gap16 {
  gap: var(--spacing-16);
}

.FixedFooterBar {
  position: fixed;
  width: calc(100% - 216px);
  right: 0;
  bottom: 0;
  z-index: 999;
}
.FixedFooterBar .MessageBtm {
  gap: 6px;
}

.SwitchCstm {
  display: flex;
  gap: var(--spacing-8);
  color: var(--font-color-secondary);
}

snl-switch {
  display: flex;
  width: 64px;
  height: 32px;
}
snl-switch.disabled {
  cursor: not-allowed;
}
snl-switch.disabled .switch__slider {
  cursor: not-allowed;
}
snl-switch.LargeSwitch .switch__wrapper {
  min-width: 100%;
  height: 100%;
}
snl-switch.LargeSwitch .switch__wrapper .switch__slider {
  min-width: 100%;
  height: 100%;
}
snl-switch.LargeSwitch .switch__wrapper .switch__slider.active .switch__toggle {
  transform: translate(0) !important;
}
snl-switch.LargeSwitch .switch__wrapper .switch__slider .switch__toggle {
  height: 26px;
  width: 26px;
  transform: translate(calc(-100% + var(--spacing-24))) !important;
  margin-top: -1px;
}
snl-switch.LargeSwitch.disabled .switch__slider {
  cursor: not-allowed;
}
snl-switch.disabled-toggle .switch__slider {
  cursor: not-allowed;
}

.container__dropdown--open {
  width: 100%;
  max-width: 350px;
}
.container__dropdown--open snl-menu-list-item {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}
.container__dropdown--open .container__menu-item {
  display: flex;
}
.container__dropdown--open .container__menu-item .container__menu-item--value {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.container__dropdown--open .container__menu-item .container__menu-item--value:hover {
  display: flex;
  overflow: inherit;
  max-height: inherit;
  width: 100%;
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  word-wrap: break-word;
  min-width: auto;
  word-break: break-all;
}

.alertMain {
  display: flex;
  align-items: center;
  gap: 32px;
  margin-bottom: var(--spacing-24);
  min-width: 1100px;
  width: fit-content;
  position: sticky;
  left: 240px;
}
.alertMain .alertIn {
  background: var(--semantic-6);
  min-width: 1000px;
  padding: var(--spacing-8) var(--spacing-16);
  min-height: 42px;
  display: flex;
  align-items: center;
  gap: var(--spacing-8);
  color: var(--font-color-primary);
  border-radius: var(--spacing-4);
  justify-content: space-between;
}
.alertMain .alertIn .alertInner {
  display: flex;
  gap: var(--spacing-8);
}
.alertMain .alertIn .alertInner svg-icon {
  display: inline-flex;
}
.alertMain a {
  color: var(--primary-default);
  font-weight: 700;
}

.DragDropFile.disable-upload {
  cursor: not-allowed;
  opacity: 0.5;
}
.DragDropFile.disable-upload .file__choose {
  pointer-events: none;
}
.DragDropFile .caption {
  color: var(--font-color-secondary);
}
.DragDropFile .file__choose {
  border: var(--spacing-1) dashed var(--neutral-8);
  color: var(--font-color-secondary) !important;
  border-radius: var(--spacing-8);
  min-height: 80px;
  display: flex;
  align-items: center;
  gap: var(--spacing-24);
  justify-content: space-between;
  padding: var(--spacing-16);
  margin: var(--spacing-8) 0 var(--spacing-4) 0;
  cursor: pointer;
}
.DragDropFile .file__choose:hover {
  border: 1px dashed var(--Primary-P3---Selected, #004869);
}
.DragDropFile .file__choose:hover .uploadBtn {
  background-color: var(--primary-accent);
  color: var(--primary-hover);
}
.DragDropFile .file__choose:hover .uploadBtn svg path {
  fill: var(--primary-hover);
}
.DragDropFile .file__choose .uploadBtn {
  min-width: 162px;
  border: 1px solid #008ECF;
  color: #008ECF;
  border-radius: var(--spacing-4);
  min-height: var(--spacing-48);
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  justify-content: center;
  padding: 8px 32px;
}
.DragDropFile .file__choose .uploadBtn:hover {
  background-color: var(--primary-accent);
  color: var(--primary-hover);
}
.DragDropFile .file__choose .uploadBtn:hover svg path {
  fill: var(--primary-hover);
}
.DragDropFile .file__choose #upload-file {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 0;
}

.clr__secondary {
  color: #64748B;
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.floating-input-container {
  position: relative;
  width: 100%;
}
.floating-input-container svg-icon {
  position: absolute;
  top: 16px;
  right: 16px;
}
.floating-input-container .floating-input {
  width: 100%;
  padding: 14px 41px 14px var(--spacing-12);
  font-size: var(--spacing-16);
  border: var(--border-width-1) solid var(--neutral-9);
  border-radius: var(--spacing-4);
  box-sizing: border-box;
  outline: none;
}
.floating-input-container .floating-input:focus {
  border-color: var(--primary-default);
}
.floating-input-container .floating-input::placeholder {
  color: transparent;
}
.floating-input-container .floating-input:focus + .floating-label, .floating-input-container .floating-input:not(:placeholder-shown) + .floating-label {
  top: 0;
  left: 10px;
  font-size: var(--spacing-12);
  line-height: var(--spacing-16);
  color: var(--primary-default);
  background: var(--neutral-12);
  padding: 0 var(--spacing-2);
}
.floating-input-container .floating-label {
  position: absolute;
  top: 50%;
  left: var(--spacing-12);
  transform: translateY(-50%);
  font-size: var(--spacing-16);
  line-height: var(--spacing-24);
  pointer-events: none;
  transition: all 0.3s ease;
}
.floating-input-container .DropDownCstm {
  position: absolute;
  background: var(--neutral-12);
  z-index: 99;
  box-shadow: 0px 10px 15px 0px rgba(15, 26, 42, 0.1019607843);
  width: 100%;
  margin-top: var(--spacing-8);
  border: var(--spacing-1) solid #f7ecec;
  border-radius: var(--spacing-4);
}
.floating-input-container .DropDownCstm ul {
  gap: 0 !important;
}
.floating-input-container .DropDownCstm ul li {
  padding: var(--spacing-12) var(--spacing-16);
  color: var(--font-color-primary);
  display: inline !important;
  cursor: pointer;
}
.floating-input-container .DropDownCstm ul li:hover {
  background-color: var(--neutral-11);
}

.NoDataFoundCenter {
  position: sticky;
  left: 50%;
  transform: translate(-50%, 0);
}
.NoDataFoundCenter .data-text .overflowing {
  display: flex;
}

.container__dropdown-border--selected {
  border: var(--border-width-1) solid var(--neutral-8) !important;
}

.container__dropdown-border:hover {
  border: var(--border-width-1) solid var(--primary-hover) !important;
}

.star-required {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-16);
}
.star-required .note {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  color: var(--font-color-primary);
  font-weight: 400;
}

/* ---------------------------------------------------
    PAGE TABS WITH ICONS STYLE
----------------------------------------------------- */
.TabsHolder {
  padding-top: var(--spacing-16);
  border-bottom: var(--spacing-1) solid var(--neutral-9);
  margin-bottom: var(--spacing-8);
}
.TabsHolder snl-page-tab .page-tab__content {
  display: flex;
  gap: var(--spacing-8);
}
.TabsHolder ul {
  padding: 0 var(--spacing-16);
  display: flex;
  gap: var(--spacing-24);
  margin-bottom: var(--spacing-24);
}
.TabsHolder ul li.Stolt-Skeleton {
  width: 100%;
  max-width: 100px;
  min-height: 24px;
  border-radius: 20px;
}

/* ---------------------------------------------------
  STOLT CCD SIDEBAR CUSTOMIZATION STYLE
----------------------------------------------------- */
.sidebar {
  position: fixed;
  z-index: 9999;
}
.sidebar .scroll {
  overflow-x: hidden;
}

.leftbar-collapsed .DataBodyContainer {
  padding-left: var(--spacing-72) !important;
}
.leftbar-collapsed .FixedFooterBar {
  width: calc(100% - 77px);
}

/* ---------------------------------------------------
  STOLT CCD TRUNCATE CUSTOMIZATION STYLE
----------------------------------------------------- */
.truncate-text {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.rotating-icon {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ck-balloon-panel {
  display: none !important;
}

.ck-editor__editable_inline {
  max-height: 150px;
}
.ck-editor__editable_inline::-webkit-scrollbar {
  width: var(--spacing-8);
  height: var(--spacing-8);
}
.ck-editor__editable_inline::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}
.ck-editor__editable_inline::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}
.ck-editor__editable_inline::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

.container__dropdown--open-actions {
  padding-top: 0 !important;
}